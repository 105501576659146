import { downloadAppClickedAction } from "actions/SmsLinkActions";
import { openURL } from "./GeneralUtils";
import AppConfig from "config/AppConfig";
const ONE_DAY = 24 * 60 * 60 * 1000;

export const downloadAppClick = async (googlePlayClicked: boolean) => {
  try {
    const localStorageObject = localStorage.getItem("smsLinkParams");
    if (localStorageObject) {
        const smsLinkParams = JSON.parse(localStorageObject);
        const livingTime = new Date();
        const timeSpent = livingTime.getTime() - new Date(smsLinkParams?.time).getTime();
        if (timeSpent && timeSpent <= ONE_DAY) {
        const requestData = {
            ObjectId: smsLinkParams.objectId,
            SmsType: smsLinkParams.smsType,
            IsGoogle: googlePlayClicked,
            IsApple: !googlePlayClicked,
        };
        await downloadAppClickedAction(requestData);
        }
    }
  }
  catch (error) {
    console.error('downloadAppClick error: ', error)
  }
  finally {
    if (googlePlayClicked) {
        openURL(AppConfig.googlePlayUrl);
      }
      else {
        openURL(AppConfig.appleStoreUrl);
      }
  }
};
