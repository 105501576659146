import { FC, useEffect, useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import { getERecordWithToken } from "services/Endpoints/EserviceBook/ERecordsEndpoint";
import { ERecord, ERecordItems, Reminder } from "data/types";
import EServiceBookFetchService from "services/Endpoints/EserviceBook/EServiceBookFetchService";
import defaultProfile from "../../shared/Logo/default-profile.png";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";
import btnIosPng from "images/btn-ios.png";
import btnAndroidPng from "images/btn-android.png";
import {
  FUEL_TYPES_LABELS,
  VEHICLE_TYPE_LABELS,
} from "utils/BusinessConstants";
import ERecordDataAvailableInfo from "components/SectionBecomeAnAuthor/ERecordDataAvailableInfo";
import AppConfig from "config/AppConfig";
import { downloadAppClicked } from "services/Endpoints/EserviceBook/SmsLinkEndpoints";
import I404Png from "images/404.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import PageExpired from "images/page-expired-image.webp";
import { SMS_LINK_TYPE } from "utils/VehicleAccessConstants";
import { downloadAppClickedAction } from "actions/SmsLinkActions";
import { downloadAppClick } from "utils/SmsClickedUtils";

const NewERecordSmsLink: FC<{ id: string | undefined }> = ({ id }) => {
  const [eRecord, setERecord] = useState<ERecord>();
  const [serviceExpanded, setServiceExpanded] = useState<boolean>(true);
  const [reminderExpanded, setReminderExpanded] = useState<boolean>(true);
  const [isBlurred, setIsBlurred] = useState(true);

  useEffect(() => {
    const fetchData = async (token: string) => {
      try {
        const response = await getERecordWithToken(token);
        if (!response.ok) {
          throw new Error("response not ok");
        }

        const data = await response.json();
        setERecord(data.value);
        const smsLinkParams = {
          objectId: data.value.id,
          time: new Date(),
          smsType: SMS_LINK_TYPE.CREATE_ERECORD,
        };
        localStorage.setItem("smsLinkParams", JSON.stringify(smsLinkParams));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (id) {
      fetchData(id?.split("r-")[1]);
    }
  }, []);

  const imageURLS = eRecord?.profileImages.map((x) =>
    EServiceBookFetchService.imageUrl(x.url)
  );

  const websiteUrl = eRecord?.businessWebsite?.startsWith("http")
    ? eRecord?.businessWebsite
    : `https://${eRecord?.businessWebsite}`;

  const tipoviGoriva = Object.entries(FUEL_TYPES_LABELS);
  const tipoviVozila = Object.entries(VEHICLE_TYPE_LABELS);
  const renderContent = () => {
    return (
      <>
        <div className=" flex flex-col sm:rounded-2xl sm:border border-neutral-200  space-y-4 px-0 sm:p-6 xl:p-8">
          {/* ------------------------ */}
          <div className="space-y-6">
            <h3 className=" pt-6 text-xl ml-1">
              {eRecord && eRecord.businessName} je ažurirao servisnu knjižicu
              vašeg vozila:
            </h3>
            <div className="flex flex-col sm:justify-start justify-center space-y-4 ml-3 text-[15px]">
              <div className="flex text-neutral-6000 ">
                <span className="flex-1">
                  <strong>Marka i model:</strong>
                </span>
                <span className="flex-1 text-neutral-900 ">
                  <p>
                    {`${eRecord?.vehManufacturer} ${eRecord?.vehModel} ${
                      eRecord?.vehVariant ? eRecord?.vehVariant : ""
                    }`}
                  </p>
                </span>
              </div>
              {eRecord?.vehLicPlates && (
                <div className="flex text-neutral-6000 ">
                  <span className="flex-1">
                    <strong>Registarska oznaka:</strong>
                  </span>
                  <span className="flex-1 text-neutral-900 ">
                    <span className="block">{`${eRecord?.vehLicPlates}`}</span>
                  </span>
                </div>
              )}
              {!!eRecord?.vehVIN && (
                <div className="flex justify-between text-neutral-6000 ">
                  <span className="flex-1">
                    <strong>Broj šasije:</strong>
                  </span>
                  <span className="flex-1 text-neutral-900 ">
                    <span className="block">{`${eRecord?.vehVIN}`}</span>
                  </span>
                </div>
              )}
              {!!eRecord?.vehPower && (
                <div className="flex justify-between text-neutral-6000 ">
                  <span className="flex-1">
                    <strong>Snaga vozila:</strong>
                  </span>
                  <span className="flex-1 text-neutral-900 ">
                    <span className="block">{`${eRecord?.vehPower} kW`}</span>
                  </span>
                </div>
              )}
              {/* {!!eRecord?.vehEngineCode && (
                <div className="flex justify-between text-neutral-6000 ">
                  <span className="flex-1">
                    <strong>Kod motora:</strong>
                  </span>
                  <span className="flex-1 text-neutral-900 ">
                    <span className="block">{`${eRecord?.vehEngineCode}`}</span>
                  </span>
                </div>
              )}
              {!!eRecord?.vehLoad && (
                <div className="flex justify-between text-neutral-6000 ">
                  <span className="flex-1">
                    <strong>Nosivost:</strong>
                  </span>
                  <span className="flex-1 text-neutral-900 ">
                    <span className="block">{`${eRecord?.vehLoad} kg`}</span>
                  </span>
                </div>
              )}
              {!!eRecord?.vehFuelTypePrimary && (
                <div className="flex justify-between text-neutral-6000 ">
                  <span className="flex-1">
                    <strong>Tip goriva:</strong>
                  </span>
                  <span className="flex-1 text-neutral-900 ">
                    <span className="block">
                      {`${I18nHelper.getText(
                        tipoviGoriva[eRecord?.vehFuelTypePrimary][1]
                      )} ${
                        eRecord.vehFuelTypeSecondary
                          ? "/" +
                            I18nHelper.getText(
                              tipoviGoriva[eRecord.vehFuelTypeSecondary][1]
                            )
                          : ""
                      }`}
                    </span>
                  </span>
                </div>
              )} */}
              {/* {!!eRecord?.vehType && (
                <div className="flex justify-between text-neutral-6000 ">
                  <span className="flex-1">
                    <strong>Tip vozila:</strong>
                  </span>
                  <span className="flex-1 text-neutral-900 ">
                    <span className="block">
                      {`${I18nHelper.getText(
                        tipoviVozila[eRecord?.vehType][1]
                      )}`}
                    </span>
                  </span>
                </div>
              )} */}
              {!!eRecord?.vehYearOfProduction && (
                <div className="flex justify-between text-neutral-6000 ">
                  <span className="flex-1">
                    <strong>Godište:</strong>
                  </span>
                  <span className="flex-1 text-neutral-900 ">
                    <span className="block ">
                      {`${eRecord?.vehYearOfProduction}`}
                    </span>
                  </span>
                </div>
              )}
            </div>
            {/* {isBlurred && (
              <button className="mt-4">
                Za detaljan pregled i pristup svim informacijama o vašem vozilu,
                kliknite na{" "}
                <a
                  href={AppConfig.googlePlayUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                  link
                </a>{" "}
                i preuzmite našu besplatnu{" "}
                <a
                  href="/mobilna-aplikacija"
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                  mobilnu aplikaciju.
                </a>
              </button>
            )} */}
          </div>
          {/* <div className="space-y-6">
            <div className="w-full !mt-2 border-b border-neutral-200 "></div>
            <h3 className="text-2xl font-semibold">Servis</h3>
            <div className="grid sm:grid-cols-2  space-y-6">
              <div className="col-span-1 space-x-2 space-y-6">
                {eRecord?.businessName && (
                  <div className=" text-neutral-6000 font-bold text-xl mb-4 ">
                    <span className="">
                      <strong>{eRecord?.businessName}</strong>
                    </span>
                  </div>
                )}
                {eRecord?.businessAddress && (
                  <div className="flex">
                    <LocationOnIcon className="mr-2" />
                    <span className="text-wrap">{`${eRecord?.businessAddress}, ${eRecord?.businessCity}`}</span>
                  </div>
                )}
                {eRecord?.businessWebsite && (
                  <div className="flex">
                    <LanguageIcon className="mr-2" />
                    <a
                      href={websiteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      {" "}
                      <span className="block   ">
                        <span className="">{websiteUrl}</span>
                      </span>
                    </a>
                  </div>
                )}

                {eRecord?.businessEmail && (
                  <div className="flex  items-end">
                    <EmailIcon className="mr-2" />
                    <a
                      href={`mailto:${eRecord?.businessEmail}`}
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      <span className="">{eRecord?.businessEmail}</span>
                    </a>
                  </div>
                )}
                {eRecord?.businessPhone && (
                  <div className="flex text-neutral-6000 ">
                    <LocalPhoneIcon className="mr-2" />
                    <span className="font-medium">
                      <a href={`tel:${eRecord?.businessPhone}`}>
                        {eRecord?.businessPhone}
                      </a>
                    </span>
                  </div>
                )}
              </div>
              <div className="col-span-1 flex justify-center items-center">
                <div className="w-full justify-center items-center">
                  <div className="sm:aspect-h-4 align-middle  rounded-2xl overflow-hidden flex justify-center items-center">
                    <NcImage
                      containerClassName="max-w-[300px] h-auto"
                      src={
                        //@ts-ignore
                        !!imageURLS?.length ? imageURLS[0].uri : defaultProfile
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>*/}
          <div className="space-y-6">
            <div className="w-full border-b border-neutral-200 "></div>
            <h3 className="text-2xl font-semibold">Zapis o servisiranju</h3>
            <div className="flex flex-col sm:justify-start justify-center space-y-4 ml-4">
              {eRecord?.dateServiced && (
                <div className="flex text-neutral-6000 ">
                  <span className="flex-1">
                    <strong>Datum servisa: </strong>
                  </span>
                  <span className="flex-1  text-neutral-900 ">
                    <span className="block ">{recordServiced}</span>
                  </span>
                </div>
              )}
              {eRecord?.odometer && (
                <div className="flex text-neutral-6000 ">
                  <span className="flex-1">
                    <strong>Kilometraža: </strong>
                  </span>
                  <span className="flex-1  text-neutral-900 ">
                    <span className="block ">{`${eRecord?.odometer} km`}</span>
                  </span>
                </div>
              )}
              {eRecord?.title && (
                <div className="flex justify-between text-neutral-6000 ">
                  <span className="flex-1">
                    <strong>Naslov:</strong>
                  </span>
                  <span className="flex-1  text-neutral-900 ">
                    <span className="block ">{`${eRecord?.title}`}</span>
                  </span>
                </div>
              )}
              {eRecord?.description && (
                <div className="flex justify-between text-neutral-6000 ">
                  <span className="flex-1">
                    <strong>Opis:</strong>
                  </span>
                  <span className="flex-1  text-neutral-900 ">
                    <span className="block ">{eRecord?.description}</span>
                  </span>
                </div>
              )}
              {eRecord?.price && (
                <div className="flex justify-between text-neutral-6000 ">
                  <span className="flex-1">
                    <strong>Ukupan iznos:</strong>
                  </span>
                  <span className="flex-1 text-neutral-900 ">
                    <span className="block ">{eRecord?.price + " RSD"}</span>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="w-full !mt-10 border-b border-neutral-200 "></div>
          <div
            onClick={() => setServiceExpanded(!serviceExpanded)}
            className="flex justify-between items-center"
          >
            <h3 className="text-2xl font-semibold align-center ">
              Delovi i usluge
            </h3>
            <span className="block font-bold p-2 pl-3 rounded-lg  flex items-center">
              <IconButton>
                {serviceExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </span>
          </div>
          {serviceExpanded && !!eRecord?.items.length && (
            <div key={eRecord.items[0].id} className="my-4">
              <div className="w-14 border-b border-neutral-200 "></div>
              {eRecord.items[0].name && (
                <div className="flex flex-row !mt-6  text-neutral-6000 ">
                  <span className="flex-1 mx-2 font-bold">Naziv:</span>
                  <span className={isBlurred ? "blur" : "flex-1 mx-2 "}>
                    {"Skinite besplatnu mobilnu aplikaciju"}
                    {/* {`${eRecord.items[0].name}   ${
                      eRecord.items[0].brandName
                        ? `(${eRecord.items[0].brandName} ${
                            eRecord.items[0].catalogCode
                              ? eRecord.items[0].catalogCode
                              : ""
                          })`
                        : ""
                    } `} */}
                  </span>
                </div>
              )}
              {eRecord.items[0].quantity && (
                <div className="flex flex-row !mt-6  text-neutral-6000 ">
                  <span className="flex-1 mx-2 font-bold">Količina:</span>
                  <span className={isBlurred ? "blur" : "flex-1 mx-2 "}>
                    {`1 *`}
                  </span>
                </div>
              )}
              {eRecord.items[0].price && (
                <div className="flex flex-row !mt-6  text-neutral-6000 ">
                  <span className="flex-1 mx-2 font-bold">Cena:</span>
                  <span className={isBlurred ? "blur" : "flex-1 mx-2 "}>
                    {"1 RSD"}
                  </span>
                </div>
              )}
            </div>
          )}
          {isBlurred && eRecord?.items.length ? (
            <button className="mt-4">
              Za pristup svim informacijama o ugrađenim delovima i izvršenim
              uslugama preuzmite našu besplatnu mobilnu aplikaciju
            </button>
          ) : (
            <button className="mt-4">
              Ovaj zapis ne sadrži informacije o ugrađenim delovima i izvršenim
              uslugama. Ukoliko želite da ih samostalno kreirate za svoje
              vozilo, preuzmite našu besplatnu mobilnu aplikaciju
            </button>
          )}
          <div className="max-w-sm mx-4 col-span-1 mt-2 md:col-span-1  text-sm space-y-4">
            <div className="h-auto flex justify-between space-x-3 mt-6">
              <a
                onClick={() => downloadAppClick(true)}
                className="cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="max-w-[120px]" src={btnAndroidPng} alt="" />
              </a>
              <a
                onClick={() => downloadAppClick(false)}
                className="cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="max-w-[120px]" src={btnIosPng} alt="" />
              </a>
            </div>
          </div>
          <div className="w-full border-b border-neutral-200 "></div>
          <div
            onClick={() => setReminderExpanded(!reminderExpanded)}
            className="flex justify-between items-center"
          >
            <h3 className="text-2xl font-semibold ">Podsetnik</h3>
            <span className="block font-bold p-2 pl-3 rounded-lg  flex ">
              <IconButton>
                {reminderExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </span>
          </div>
          {reminderExpanded &&
            eRecord?.reminders &&
            eRecord.reminders.map((x: Reminder) => {
              const dueDate = formatDateFromString(x.dueDate);

              return (
                <div key={x.id} className="my-6">
                  {x.title && (
                    <div className="flex flex-row !mt-4  text-neutral-6000 ">
                      <span className="flex-1 mx-2 font-bold">Naslov:</span>
                      <span className={isBlurred ? "blur" : "flex-1 mx-2 "}>
                        {/* {x.title} */}
                        {"Skinite besplatnu mobilnu aplikaciju"}
                      </span>
                    </div>
                  )}
                  {x.note && (
                    <div className="flex flex-row !mt-4  text-neutral-6000 ">
                      <span className="flex-1 mx-2 font-bold">Beleška:</span>
                      <span className={isBlurred ? "blur" : "flex-1 mx-2 "}>
                        {"Besplatna mobilna aplikacija"}
                        {/* {x.note} */}
                      </span>
                    </div>
                  )}
                  {x.dueDate && (
                    <div className="flex flex-row !mt-4  text-neutral-6000 ">
                      <span className="flex-1 mx-2 font-bold">
                        Krajnji datum:{" "}
                      </span>
                      <span className={isBlurred ? "blur" : "flex-1 mx-2 "}>
                        {"**/**/****"}
                        {/* {dueDate} */}
                      </span>
                    </div>
                  )}
                  {x.odometer && (
                    <div className="flex flex-row !mt-4  text-neutral-6000 ">
                      <span className="flex-1 mx-2 font-bold">
                        Kilometraža:
                      </span>
                      <span className={isBlurred ? "blur" : "flex-1 mx-2 "}>
                        {/* {x.odometer + " km"} */}
                        {"1" + " km"}
                      </span>
                    </div>
                  )}
                  <div className="w-full !mt-14 border-b border-neutral-200 "></div>
                </div>
              );
            })}
          {isBlurred && eRecord?.reminders.length ? (
            <button className="mt-4">
              Za pregled svih podsetnika, preuzmite našu besplatnu mobilnu
              aplikaciju
            </button>
          ) : (
            <button className="mt-4">
              Ovaj zapis ne sadrži podsetnike. Ukoliko želite da samostalno
              kreirate podsetnike za svoje vozilo, preuzmite našu besplatnu
              mobilnu aplikaciju
            </button>
          )}
          <div className="max-w-sm mx-4 col-span-1 mt-2 md:col-span-1  text-sm space-y-4">
            <div className="h-auto flex justify-between space-x-3 mt-6">
              <a
                onClick={() => downloadAppClick(true)}
                className="cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="max-w-[120px]" src={btnAndroidPng} alt="" />
              </a>
              <a
                onClick={() => downloadAppClick(false)}
                className="cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="max-w-[120px]" src={btnIosPng} alt="" />
              </a>
            </div>
          </div>
        </div>
        {eRecord && (
          <ERecordDataAvailableInfo
            smsLinkExpiryPeriodInDays={eRecord?.smsLinkExpiryPeriodInDays}
            className="sm:rounded-2xl my-12 sm:border border-neutral-200 bg-neutral-100  "
          />
        )}
      </>
    );
  };

  const formatDateFromString = (dateString: string): string => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      // Handle invalid date
      return "Invalid Date";
    }

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };
  const recordServiced = formatDateFromString(
    eRecord?.dateServiced ? eRecord.dateServiced : ""
  );

  return !eRecord ? (
    <div className="pt-10 grid">
      <div className="text-center">
        {`Stranica sa detaljima vašeg vozila je istekla, ukoliko želite da vidite detalje vašeg vozila preuzmite
        našu besplatnu mobilnu aplikaciju`}
      </div>
      <div className="h-auto w-full flex justify-center space-x-3 mt-6">
        <div className="mr-12">
          {" "}
          <a
            onClick={() => downloadAppClick(true)}
            className="cursor-pointer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={btnAndroidPng} alt="" />
          </a>
        </div>

        <div>
          <a
            onClick={() => downloadAppClick(false)}
            className="cursor-pointer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={btnIosPng} alt="" />
          </a>
        </div>
      </div>
      <div className="justify-self-center py-8 px-[-8px]">
        <NcImage src={PageExpired} />
      </div>
      {/* <div className="max-w-sm mx-4 col-span-1 mt-2 md:col-span-1  text-sm space-y-4">
        <div className="h-auto flex justify-between space-x-3 mt-6">
          <a
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="max-w-[120px]" src={btnAndroidPng} alt="" />
          </a>
          <a
           className="opacity-75 pointer-events-none"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="max-w-[120px]" src={btnIosPng} alt="" />
          </a>
        </div>
      </div> */}
    </div>
  ) : (
    <div>
      <div className="block flex-grow mb-10 lg:mb-0">
        <div className="lg:sticky lg:top-24">{renderContent()}</div>
      </div>
    </div>
  );
};

export default NewERecordSmsLink;
