// eslint-disable-next-line no-bitwise
export function convertParamsToUrlSearchString(urlParameters) {
  const urlParametersKeys = Object.keys(urlParameters).filter(
    (key) => urlParameters[key] !== null && urlParameters[key] !== undefined
  );
  if (urlParametersKeys.length > 0) {
    return urlParametersKeys
      .map((key) => {
        const value = urlParameters[key];
        if (Array.isArray(value)) {
          return value
            .filter((x) => x !== null && x !== undefined)
            .map((arrValue) => `${key}=${encodeURIComponent(arrValue)}`)
            .join("&");
        }
        return `${key}=${encodeURIComponent(value)}`;
      })
      .join("&");
  }
}

export function openURL(url, onError) {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
