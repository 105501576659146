import { FC } from "react";
import Heading from "components/Heading/Heading";
import btnIosPng from "images/btn-ios.png";
import btnAndroidPng from "images/btn-android.png";
import AppConfig from "config/AppConfig";
import { downloadAppClick } from "utils/SmsClickedUtils";
import { Helmet } from "react-helmet";


export interface MobAppFeaturesPageProps {
  className?: string;
}

const MobAppFeaturesPage: FC<MobAppFeaturesPageProps> = ({
  className = "",
}) => {
  function isMobileView() {
    return window.innerWidth <= 768; // Adjust the breakpoint as needed
  }

  // Example usage:
  const isMobile = isMobileView();
  return (
    <div
      className={`nc-PageContact overflow-hidden px-6 ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Mobilna aplikacija</title>
        <meta property="og:title" content="Mobilna aplikacija"></meta>
      </Helmet>
      <div className="mb-10 text-center">
        <h2 className="mt-10 text-3xl md:text-4xl font-semibold">
          Mobilna aplikacija
        </h2>
        <span className="mt-2 mb-5 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
          Dostupna za Android i iOS platforme
        </span>
      </div>
      <div className="flex w-full space-x-4 mb-10">
        <div className="w-6/12 cursor-pointer">
          <a
            onClick={() => downloadAppClick(true)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={isMobile ? "mx-auto" : "float-right mr-2"}
              src={btnAndroidPng}
              alt=""
            />
          </a>
        </div>

        <div className="w-6/12 cursor-pointer">
          <a
            onClick={() => downloadAppClick(false)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={isMobile ? "mx-auto" : "float-left ml-2"}
              src={btnIosPng}
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="container">
        <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
          <div className="p-6 bg-neutral-50  rounded-2xl">
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl">
              Evidencija vozila
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base">
              Sva porodična ili kompanijska vozila na jednom mestu. Promenite
              aktivno vozilo i u trenutku su vam dostupne sve njegove opcije
            </span>
          </div>
          <div className="p-6 bg-neutral-50 rounded-2xl">
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl">
              Vozilo u džepu
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base">
              Podaci sa saobraćajne dozvole su uvek sa vama, broj šasije,
              motora, snaga, zapremina...ove informacije vam mogu zatrebati
              prilikom registracije vozila i traženja ponuda za npr. kasko
              osiguranje ili rezervne delove
            </span>
          </div>
          <div className="p-6 bg-neutral-50 rounded-2xl">
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl">
              Servisna knjižica
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base">
              Istorija održavanja vozila zajedno sa svim računima vam je uvek
              dostupna, možete je samostalno ažurirati i podeliti sa svojim
              serviserom ili potencijalnim kupcima vozila.
            </span>
          </div>
          <div className="p-6 bg-neutral-50 rounded-2xl">
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl">
              Podsetnici
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base">
              Ne zaboravite na istek registracije, vozačke i saobraćajne
              dozvole, sledeći termin za redovni servis vozila i slično.
            </span>
          </div>
          <div className="p-6 bg-neutral-50  rounded-2xl">
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl">
              Točenja goriva
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base">
              Možete voditi evidenciju svih točenja goriva i pratiti potrošnju,
              takođe i uz svako točenje priložiti i fotografije računa.
            </span>
          </div>
          <div className="p-6 bg-neutral-50 rounded-2xl">
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl">
              Pratite troškove
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base">
              Budite u kontroli, pratite koliko vas košta vozilo, od održavanja
              i registracije, do goriva, pranja i putarina - vi određujete koje
              troškove pratite.
            </span>
          </div>
          <div className="p-6 bg-neutral-50  rounded-2xl">
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl">
              Kalkulator registracije
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base">
              Za sva vaša vozila, cena registracije vam je dostupna na klik.{" "}
            </span>
          </div>
          <div className="p-6 bg-neutral-50 rounded-2xl">
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl">
              Zakažite tehnički / servis
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base">
              U našem adresaru možete pronaći svoj omiljeni tehnički pregled /
              auto servis i na jednostavan način ih kontaktirati i zakazati
              termin.
            </span>
          </div>
          <div className="p-6 bg-neutral-50 rounded-2xl">
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl">
              Adresar
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base">
              Pretražite adresar servisa svih delatnosti, kao i tehničkih
              pregleda. Proverite rejtinge, cenovnik, i zatražite ponudu putem
              aplikacije.
            </span>
          </div>
        </div>
        <br></br>
      </div>
    </div>
  );
};

export default MobAppFeaturesPage;
