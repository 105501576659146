import { FC, useEffect, useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Vehicle } from "data/types";
import VehicleInviteDataAvailableInfo from "components/SectionBecomeAnAuthor/VehicleInviteDataAvailableInfo";
import FullScreenLoading from "routers/FullScreenLoading";
import btnIosPng from "images/btn-ios.png";
import btnAndroidPng from "images/btn-android.png";
import PageExpired from "images/page-expired-image.webp";
import { getVehicleWithAccessInviteAction } from "actions/VehicleAccessActions";
import { downloadAppClick } from "utils/SmsClickedUtils";
import { SMS_LINK_TYPE } from "utils/VehicleAccessConstants";

const NewVehicleAccessInviteSmsLink: FC<{ id: string | undefined }> = ({
  id,
}) => {
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [isBlurred, setIsBlurred] = useState(true);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async (accessToken: string) => {
      setLoading(true);
      const responseData = await getVehicleWithAccessInviteAction(
        accessToken
      );
      if (responseData.success) {
        setVehicle(responseData.value);
        const smsLinkParams = {
          objectId: responseData.value.vehAccessInvite.id,
          time: new Date(),
          smsType: SMS_LINK_TYPE.CREATE_VEHICLE,
        };
        localStorage.setItem("smsLinkParams", JSON.stringify(smsLinkParams));
      }
      setLoading(false);
    };

    if (id) {
      fetchData(id?.split("i-")[1]);
    }
  }, []);

  const renderContent = () => {
    const titleText = vehicle?.vehAccessInvite.isCreatorCompany
      ? "Otvorena servisna knjižica"
      : "Dodeljeno pravo pristupa vozilu";
    let subtitleText;
    if (vehicle) {
      subtitleText = vehicle.vehAccessInvite.isCreatorCompany
        ? `${vehicle.vehAccessInvite.inviteCreatorName} vam je otvorio servisnu knjižicu za Vaše vozilo: `
        : `${vehicle.vehAccessInvite.inviteCreatorName} vam je dodelio pristup vozilu: `;
    }

    return (
      <>
        <header className="text-center max-w-2xl mx-auto my-12">
          <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 justify-center">
            {titleText}
          </h2>
        </header>
        <div className=" flex flex-col sm:rounded-2xl sm:border border-neutral-200 space-y-4 px-0 sm:p-6 xl:p-8">
          {/* ------------------------ */}
          <div className="space-y-6">
            <h3 className="text-xl ml-1">{subtitleText}</h3>
            {/* <div className="w-14 border-b border-neutral-200"></div> */}
            <div className="flex flex-col sm:justify-start justify-center space-y-4 ml-3 text-[15px]">
              <div className="flex text-neutral-6000">
                <span className="flex-1">
                  <strong>Marka i model:</strong>
                </span>
                <span className="flex-1 text-neutral-900">
                  <p>
                    {`${vehicle?.manufacturer} ${vehicle?.model} ${
                      vehicle?.variant ? vehicle?.variant : ""
                    }`}
                  </p>
                </span>
              </div>
              {vehicle?.licPlates && (
                <div className="flex text-neutral-6000">
                  <span className="flex-1">
                    <strong>Registarska oznaka:</strong>
                  </span>
                  <span className="flex-1 text-neutral-900">
                    <span className="block">{`${vehicle?.licPlates}`}</span>
                  </span>
                </div>
              )}
              {!!vehicle?.vin && (
                <div className="flex justify-between text-neutral-6000">
                  <span className="flex-1">
                    <strong>Broj šasije:</strong>
                  </span>
                  <span className="flex-1 text-neutral-900">
                    <span className="block">{`${vehicle?.vin}`}</span>
                  </span>
                </div>
              )}
              {!!vehicle?.power && (
                <div className="flex justify-between text-neutral-6000">
                  <span className="flex-1">
                    <strong>Snaga vozila:</strong>
                  </span>
                  <span className="flex-1 text-neutral-900">
                    <span className="block">{`${vehicle?.power} kW`}</span>
                  </span>
                </div>
              )}
              {!!vehicle?.yearOfProduction && (
                <div className="flex justify-between text-neutral-6000">
                  <span className="flex-1">
                    <strong>Godište:</strong>
                  </span>
                  <span className="flex-1 text-neutral-900">
                    <span className="block">
                      {`${
                        vehicle?.yearOfProduction
                          ? `${vehicle?.yearOfProduction}`
                          : ""
                      }`}
                    </span>
                  </span>
                </div>
              )}
            </div>
            {isBlurred && (
              <div className="mt-4 text-center">
                Za detaljan pristup svim informacijama o vašem vozilu, preuzmite
                našu besplatnu mobilnu aplikaciju.
              </div>
            )}
          </div>
          <div className="h-auto w-full flex justify-center space-x-3 mt-6">
            <div className="mr-12">
              {" "}
              <a
                onClick={() => downloadAppClick(true)}
                className="cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={btnAndroidPng} alt="" />
              </a>
            </div>

            <div>
              <a
                onClick={() => downloadAppClick(false)}
                className="cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={btnIosPng} alt="" />
              </a>
            </div>
          </div>
        </div>

        {vehicle && (
          <VehicleInviteDataAvailableInfo
            smsLinkExpiryPeriodInDays={vehicle.smsLinkExpiryPeriodInDays}
            className="sm:rounded-2xl my-12 sm:border border-neutral-200 bg-neutral-100  "
          />
        )}
      </>
    );
  };

  if (loading) {
    return <FullScreenLoading />;
  }

  return !vehicle ? (
    <div className="pt-10 grid">
      <div className="text-center">
        Stranica sa detaljima vašeg vozila je istekla, ukoliko želite da vidite
        detalje vašeg vozila preuzmite našu besplatnu mobilnu aplikaciju
      </div>
      <div className="h-auto w-full flex justify-center space-x-3 mt-6">
        <div className="mr-12">
          {" "}
          <a
            onClick={() => downloadAppClick(true)}
            className="cursor-pointer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={btnAndroidPng} alt="" />
          </a>
        </div>

        <div>
          <a
            onClick={() => downloadAppClick(false)}
            className="cursor-pointer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={btnIosPng} alt="" />
          </a>
        </div>
      </div>
      <div className="justify-self-center py-8 px-[-8px]">
        <NcImage src={PageExpired} />
      </div>
    </div>
  ) : (
    <div>
      <div className="block flex-grow mb-10 lg:mb-0">
        <div className="lg:sticky lg:top-24">{renderContent()}</div>
      </div>
    </div>
  );
};

export default NewVehicleAccessInviteSmsLink;
