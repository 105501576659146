import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng2 from "images/phone-car-mechanic.jpg";
import { useNavigate } from "react-router-dom";
import btnIosPng from "images/btn-ios.png";
import btnAndroidPng from "images/btn-android.png";
import AppConfig from "config/AppConfig";
import { downloadAppClick } from "utils/SmsClickedUtils";

export interface ServiceHistoryHomeProps {
  className?: string;
}

const ServiceHistoryHome: FC<ServiceHistoryHomeProps> = ({
  className = "",
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10  lg:pb-48 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-semibold text-4xl md:text-5xl xl:text-4xl !leading-[114%] ">
            Da li znate servisnu istoriju svog vozila?
          </h2>
          <span className="text-base md:text-lg text-neutral-500">
            E-Knjižica Vam pruža potpun uvid u istoriju održavanja i popravki,
            na svim lokacijama gde je vozilo servisirano. Daje vam bolji uvid da
            je posao obavljen i dokumentovan ispravno, što Vam kao vlasniku
            vozila obezbeđuje manje problema i najduži mogući vek trajanja Vašeg
            automobila.
            <br />
            <br />
            Jednostavno je i besplatno za vlasnike automobila.
          </span>
          <span className="text-base md:text-lg text-neutral-500">
            E-Knjižicu možete otvoriti sami koristeći našu mobilnu aplikaciju:
          </span>

          <div className="flex w-full space-x-4">
            <div className="w-6/12 cursor-pointer">
              <a
                onClick={() => downloadAppClick(true)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="mx-auto" src={btnAndroidPng} alt="" />
              </a>
            </div>
            <div className="w-6/12 cursor-pointer">
              <a
                onClick={() => downloadAppClick(false)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={btnIosPng} className="mx-auto" alt="" />
              </a>
            </div>
          </div>
          <span className="text-base md:text-lg text-neutral-500">
            ili posetite neki od autorizovanih auto servisa i tehničkih pregleda
            iz našeg adresara:
          </span>
          <div className="text-center w-full">
            <ButtonPrimary
              className="bg-sky-400 hover:bg-sky-400"
              onClick={() => navigate("/adresar")}
            >
              Pretražite adresar
            </ButtonPrimary>
          </div>
        </div>
        <div>
          <img className="max-h-[700px]" src={imagePng2} alt="hero" />
        </div>
      </div>
    </div>
  );
};

export default ServiceHistoryHome;
